import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"

import SectionTitle from "../sectionTitle"

const DesktopAbout = () => {
  const { t } = useTranslation()

  return (
    <div className="hidden md:block">
      <StaticImage
        src="../../images/Desktop/04.about_banner@2x.png"
        alt="關於布蘭登公爵"
        className="w-full"
        placeholder="blurred"
      />
      <div className="flex items-stretch">
        <div className="flex-1">
          <div className="flex flex-col justify-center items-center px-12 py-24 h-full">
            <h2
              className="text-2xl font-normal"
              style={{ letterSpacing: "0.5em" }}
            >
              {t("about.title1")}
            </h2>
            <div
              className="mt-6 mb-7 bg-gold"
              style={{ height: 2, width: 100 }}
            ></div>
            <p
              className="font-light text-base"
              style={{ letterSpacing: "0.3em", maxWidth: 477 }}
            >
              {t("about.title1Des1")}
            </p>
          </div>
        </div>
        <div className="flex-1">
          <StaticImage
            src="../../images/Desktop/04.banner_1@2x.png"
            alt="精湛釀酒"
            className="w-full"
            placeholder="blurred"
          />
        </div>
      </div>
      <div className="flex items-stretch">
        <div className="flex-1">
          <StaticImage
            src="../../images/Desktop/04.banner_2@2x.png"
            alt="賴博士"
            className="w-full"
            placeholder="blurred"
          />
        </div>
        <div className="flex-1">
          <div className="flex flex-col justify-center items-center px-12 py-24 h-full">
            <h2
              className="text-2xl font-normal"
              style={{ letterSpacing: "0.5em" }}
            >
              {t("about.title2")}
            </h2>
            <div
              className="mt-6 mb-7 bg-gold"
              style={{ height: 2, width: 100 }}
            ></div>
            <p
              className="font-light text-base"
              style={{ letterSpacing: "0.3em", maxWidth: 477 }}
            >
              {t("about.title2Des1")}
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-stretch">
        <div className="flex-1">
          <div className="flex flex-col justify-center items-center px-12 py-24 h-full">
            <h2
              className="text-2xl font-normal"
              style={{ letterSpacing: "0.5em" }}
            >
              {t("about.title3")}
            </h2>
            <div
              className="mt-6 mb-7 bg-gold"
              style={{ height: 2, width: 100 }}
            ></div>
            <p
              className="font-light text-base mb-6"
              style={{ maxWidth: 477, letterSpacing: "0.3em" }}
            >
              {t("about.title3Des1")}
            </p>
            <p
              className="font-light text-base mb-6"
              style={{ maxWidth: 477, letterSpacing: "0.3em" }}
            >
              {t("about.title3Des2")}
            </p>
            <p
              className="font-light text-base mb-6"
              style={{ maxWidth: 477, letterSpacing: "0.3em" }}
            >
              {t("about.title3Des3")}
            </p>
          </div>
        </div>
        <div className="flex-1">
          <StaticImage
            src="../../images/Desktop/04.banner_3@2x.png"
            alt="精湛釀酒"
            className="w-full"
            placeholder="blurred"
          />
        </div>
      </div>
    </div>
  )
}

export default DesktopAbout
