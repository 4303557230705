import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MobileAbout from "../components/MobileAbout"
import DesktopAbout from "../components/DesktopAbout"

const About = () => (
  <>
    <Seo
      title="蘭登公爵極力追尋頂級、高雅風味 700ml"
      description="擁有超過半世紀釀酒經驗以突破性的蒸餾工藝，研究出植物經發酵蒸餾。保留蒜頭香氣及風味，酒香完美結合去撫存菁。尾韻陣陣香氣，多層次的美味口感迴盪唇齒之間。類型：中式白酒、高粱"
    />
    <DesktopAbout />
    <MobileAbout />
  </>
)

export default About
