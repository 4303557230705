import React from "react"

const SectionTitle = ({ title }) => {
  return (
    <>
      <h2 className="text-base font-normal" style={{ letterSpacing: "0.3em" }}>
        {title}
      </h2>
      <div className="my-6 bg-gold" style={{ height: 2, width: 100 }}></div>
    </>
  )
}

export default SectionTitle
