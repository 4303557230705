import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import SectionTitle from "../SectionTitle"

const MobileAbout = () => {
  const { t } = useTranslation()

  return (
    <div className="md:hidden">
      <StaticImage
        src="../../images/Mobile/04.about_banner.png"
        alt="關於布蘭登公爵"
        className="w-full"
        placeholder="blurred"
      />
      <StaticImage
        src="../../images/Mobile/04.banner_1.png"
        alt="精湛釀酒品質"
        className="w-full"
        placeholder="blurred"
      />
      <div className="flex flex-col justify-center items-center px-12 py-24">
        <SectionTitle title={t("about.title1")} />
        <p
          className="font-light text-sm leading-6 mb-10"
          style={{ maxWidth: 320, letterSpacing: "0.2em" }}
        >
          {t("about.title1Des1")}
        </p>
      </div>
      <StaticImage
        src="../../images/Mobile/04.banner_2.png"
        alt="賴博士"
        className="w-full"
        placeholder="blurred"
      />
      <div className="flex flex-col justify-center items-center px-12 py-24">
        <SectionTitle title={t("about.title2")} />
        <p
          className="font-light text-sm leading-6 mb-10"
          style={{ maxWidth: 320, letterSpacing: "0.2em" }}
        >
          {t("about.title2Des1")}
        </p>
      </div>
      <StaticImage
        src="../../images/Mobile/04.banner_3.png"
        alt="釀製關鍵來自「酒麴」"
        className="w-full"
        placeholder="blurred"
      />
      <div className="flex flex-col justify-center items-center px-12 py-24">
        <SectionTitle title={t("about.title3")} />
        <p
          className="font-light text-sm leading-6 mb-6"
          style={{ maxWidth: 320, letterSpacing: "0.2em" }}
        >
          {t("about.title3Des1")}
        </p>
        <p
          className="font-light text-sm leading-6 mb-6"
          style={{ maxWidth: 320, letterSpacing: "0.2em" }}
        >
          {t("about.title3Des2")}
        </p>
        <p
          className="font-light text-sm leading-6 mb-6"
          style={{ maxWidth: 320, letterSpacing: "0.2em" }}
        >
          {t("about.title3Des3")}
        </p>
      </div>
    </div>
  )
}

export default MobileAbout
